







































































import Vue from 'vue';
import { Practice } from '@/models/practice.model';
import { db } from '@/main';
import { Place } from '@/models/place.model';
import router from '@/router';

export default Vue.extend({
  name: 'NewPractice',
  components: {
    PlaceSearch: () => import('@/components/PlaceSearch.vue')
  },
  data() {
    return {
      router: router,
      practice: {} as Practice,
      rules: {
        required: (value: string) => !!value || 'Required.',
        mobile: (value: string) =>
          /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/.test(
            value
          ) || 'Mobile number must be valid'
      },
      isPracticeDetailsFormValid: false,
      isBrandFormValid: false,
      isTeamDetailsFormValid: false,
      snackbar: false,
      snackbarText: '',
      snackbarColor: '',
      practiceAddressText: '',
      place: undefined,
      placeLoading: false
    };
  },
  methods: {
    updateData() {
      this.practice.uid = db.collection('practices').doc().id;
      this.practice.userUids = [this.$store.state.currentUser.uid];
      this.updateUser();
      db.collection('practices')
        .doc(this.practice.uid)
        .set(this.practice, { merge: true })
        .then(() => {
          this.showSnackbar('Practice added.', 'success');
          this.router.push({ name: 'PracticeProfile' });
        })
        .catch((error: Error) => {
          this.showSnackbar(error.message, 'error');
          console.error(error);
        });
    },
    updateUser() {
      const practiceUids = this.$store.state.currentUser.practiceUids;
      practiceUids.push(this.practice.uid);
      db.collection('users')
        .doc(this.$store.state.currentUser.uid)
        .set(
          {
            practiceUids: practiceUids,
            activePractice: { name: this.practice.name, uid: this.practice.uid }
          },
          { merge: true }
        );
    },
    placeSelected(place: Place) {
      this.practice.googlePlaceId = place.googlePlaceId;
      this.practice.address = place.address;
      this.practice.city = place.city;
      this.practice.country = place.country;
      this.practice.geoPoint = place.geoPoint;
      if (place.rating) {
        this.practice.googleRating = place.rating;
        this.practice.numberOfGoogleRatings = place.numberOfRatings;
      }
    },
    onMobileNumberInput(formattedNumber, { number }) {
      this.practice.mobileNumber = number.international;
    },
    onPracticePhoneNumberInput(formattedNumber, { number }) {
      this.practice.phoneNumber = number.international;
    },
    showSnackbar(text: string, color: string) {
      this.snackbarText = text;
      this.snackbarColor = color;
      this.snackbar = true;
    }
  },
  created() {
    window.scrollTo(0, 0);
  }
});
